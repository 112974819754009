export const PowerTypeNames = {
  AMT: "amt",
  APC: "apc",
  DLI: "dli",
  EATON: "eaton",
  HMC: "hmc",
  HMCZ: "hmcz",
  IPMI: "ipmi",
  LXD: "lxd",
  MANUAL: "manual",
  MICROSOFT_OCS: "msftocs",
  MOONSHOT: "moonshot",
  MSCM: "mscm",
  NOVA: "nova",
  OPENBMC: "openbmc",
  PROXMOX: "proxmox",
  RECS_BOX: "recs_box",
  REDFISH: "redfish",
  SEAMICRO: "sm15k",
  UCSM: "ucsm",
  VIRSH: "virsh",
  VMWARE: "vmware",
  WEBHOOK: "webhook",
  WEDGE: "wedge",
} as const;
